





























































































































































































import { Component, Vue } from 'vue-property-decorator'

  @Component
export default class HelpDevice extends Vue {
  icons = [
    { value: 'mdi-pause', title: '정지', subtitle: '아무것도 안함' },
    { value: 'mdi-update', title: '업데이트 대기', subtitle: '예약이 걸려있으며 접속이 될 경우 업데이트 중으로 변경' },
    { value: 'mdi-autorenew mdi-spin', title: '업데이트 중', subtitle: '장치에 데이터를 전송 중인 상태' },
    { value: 'mdi-check', title: '업데이트 완료', subtitle: '장치에 데이터를 모두 전송함, 장치가 리셋하고 다시 접속하면 정지 상태로 됨' }
  ]

  update = true
  view = true
  selected = true
  showNormal = true
  showUpdate = true
  showGroup = true
}
