







import { Component, Vue } from 'vue-property-decorator'
import deviceCollection, { Device, DeviceInfo } from '@/models/devices'
import firmwareCollection from '@/models/firmwares'
import FirmwareStore from '@/store/firmware'
import delay from 'delay'

@Component
export default class DeviceSimulate extends Vue {
  loading = false

  get firstFirmware () {
    return FirmwareStore.items[0]
  }

  simulate () {
    for (let i = 0; i < 10; i++) {
      this.make(i)
    }
  }

  async make (i: number) {
    if (!this.firstFirmware) return
    const id = i.toString().padStart(11, '0')
    const info: DeviceInfo = {
      time: new Date(),
      driverName: '',
      driverCode: '',
      carType: 11,
      plateNo: '00가' + i.toString().padStart(4, '0'),
      vin: '',
      companyName: 'test' + i.toString(),
      companyNo: '',
      approvalNo: '',
      serialNo: '',
      modelNo: this.firstFirmware.modelNo,
      kFactor: 0,
      rpmFactor: 0,
      version: '1000',
      latLng: {
        lat: 37 + Math.random(),
        lng: 126 + Math.random()
      },
      azimuth: 0
    }

    const device = new Device(
      id,
      info,
      3,
      id,
      new Date(),
      new Date(),
      new Date(),
      0,
      false,
      firmwareCollection.doc(this.firstFirmware.id),
      0,
      { index: 0, count: 0 },
      true,
      'ota simulate',
      '',
      false,
      false,
      null
    )
    const ref = deviceCollection.doc(id)
    await ref.set(device)
    let t = Math.floor(Math.random() * 1000 * 10 + 3000)
    await delay(t)
    await ref.update({ update: 1 })

    // t = Math.floor(Math.random() * 1000 * 10 + 3000)
    // await delay(t)
    // await ref.set({ update: 2 }, { merge: true })

    for (let j = 0; j < 5; j++) {
      t = Math.floor(Math.random() * 1000 * 10)
      await delay(t)
      await ref.update({ update: 2, progress: { index: (j + 1) * 10 * 2, count: 100 } })
    }

    t = Math.floor(Math.random() * 1000 * 10 + 3000)
    await delay(t)
    await ref.update({ update: 3 })

    t = Math.floor(Math.random() * 1000 * 10 + 3000)
    await delay(t)

    const infoSet: DeviceInfo = {
      time: new Date(),
      driverName: '',
      driverCode: '',
      carType: 11,
      plateNo: '00가' + i.toString().padStart(4, '0'),
      vin: '',
      companyName: 'test' + i.toString(),
      companyNo: '',
      approvalNo: '',
      serialNo: '',
      modelNo: this.firstFirmware.modelNo,
      kFactor: 0,
      rpmFactor: 0,
      version: this.firstFirmware.version,
      latLng: {
        lat: 37 + Math.random(),
        lng: 126 + Math.random()
      },
      azimuth: 0
    }
    await ref.update({ update: 0, info: infoSet })

    t = Math.floor(Math.random() * 1000 * 10 + 20000)
    await delay(t)
    await ref.delete()
  }
}
