import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators'
import store from './index'
import api from '@/plugins/axios'
import AlgoliaSearch from '@/plugins/algolia'

// import firebase from '@/plugins/firebase'
// import { Model } from '@/models/models'

@Module({
  namespaced: true,
  store,
  name: 'algolia',
  dynamic: true
})

class AlgoliaStore extends VuexModule {
  appId = process.env.VUE_APP_ALGOLIA_APP_ID
  key = localStorage.getItem('algoliaKey')

  get index () {
    if (!this.key) return null
    const client = AlgoliaSearch(this.appId, this.key)
    return client.initIndex('devices')
  }

  @Mutation
  setKey (key: string) {
    this.key = key
    localStorage.setItem('algoliaKey', key)
  }

  @Action({ commit: 'setKey' })
  async getKey () {
    const r = await api.get<string>('/algolia-key')
    return r.data
  }

  @Action({ commit: 'setItem' })
  async getDevices (query: string) {
    if (!this.index) return null
    return this.index.search(query)
  }
}
export default getModule(AlgoliaStore)
