
















































































































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { Debounce } from 'vue-debounce-decorator'
import { DataOptions } from 'vuetify/types'
import deviceCollection, { Device } from '@/models/devices'
import { SettingsHeaders, UserSettings } from '@/models/users'
import firmwareCollection, { Firmware } from '@/models/firmwares'
import logAdd from '@/components/log/add'
import DeviceListAdd from '@/components/device/list-add.vue'
import DeviceListActions from '@/components/device/list-actions.vue'
import DisplayTime from '@/components/common/display-time.vue'
import DisplayUpdateStart from '@/components/common/display-update-start.vue'
import DisplayUpdateEnd from '@/components/common/display-update-end.vue'
import DisplayLog from '@/components/common/display-log.vue'
import DisplayModel from '@/components/common/display-model.vue'
import DeviceItem from '@/components/device/item.vue'
import DeviceItemLogs from '@/components/device/item-logs.vue'
import DeviceSimulate from '@/components/device/simulate.vue'
import HelpDevice from '@/components/help/device.vue'
import firebase from '@/plugins/firebase'
import modelStore from '@/store/model'
import AlgoliaStore from '@/store/algolia'
import authStore from '@/store/auth'
import FirmwareStore from '@/store/firmware'

interface HeaderItem {
  value: string;
  text: string;
  selected: boolean;
}

@Component<DeviceList>({
  components: {
    DeviceListAdd,
    DeviceListActions,
    DisplayTime,
    DisplayUpdateStart,
    DisplayUpdateEnd,
    DisplayLog,
    DisplayModel,
    DeviceItem,
    DeviceItemLogs,
    DeviceSimulate,
    HelpDevice
  },
  created () {
    if (!AlgoliaStore.key) {
      AlgoliaStore.getKey()
    }
  },
  destroyed () {
    this.destroy()
  }
})
export default class DeviceList extends Vue {
  @Prop({ type: String }) modelNo!: string | null
  unsubscribe: Function | null = null
  snapshots: firebase.firestore.QueryDocumentSnapshot<Device>[] = []

  headers: HeaderItem[] = authStore.user?.settings.headers || []

  showEditHeader = false

  sortAsc = true
  loading = false

  options = {
    page: 1,
    sortBy: [authStore.user?.settings.sortBy] || ['modemNo'],
    sortDesc: [authStore.user?.settings.sortDesc] || [false],
    itemsPerPage: 10
  }

  selectedModel = this.modelNo || authStore.user?.settings.selectedModel || ''

  selectedItems: Device[] = []

  showAction = false
  persistentAction = false

  form = {
    update: null,
    log: null,
    firmware: null
  }

  deviceAdd = false

  searchText = ''

  beforeItems: Device[] = []

  dialog = false

  @Watch('options')
  onChangedOption (newOptions: DataOptions, oldOptions: DataOptions) {
    const arrow = newOptions.page - oldOptions.page
    this.subscribe(arrow)
    if (newOptions.sortBy[0] !== oldOptions.sortBy[0] ||
     newOptions.sortDesc[0] !== oldOptions.sortDesc[0]) {
      this.updateSettings()
    }
  }

  @Watch('headers', { deep: true })
  onChangeheaders () {
    if (!this.headers) return
    this.updateSettings()
  }

  updateSettings () {
    const settings: UserSettings = {
      headers: this.headers,
      sortBy: this.options.sortBy[0],
      sortDesc: this.options.sortDesc[0],
      selectedModel: this.selectedModel,
      dark: authStore.user?.settings.dark
    }
    authStore.updateSettings(settings)
  }

  changeSelectedModel (n: string) {
    this.clear()
    this.updateSettings()
    if (n) return
    if (!this.$route.query.modelNo) return
    this.$router.replace('/device')
  }

  @Watch('searchText')
  onSearchTextChanged (n: string) {
    // console.log('searchText watch')
    if (!n) {
      this.clear()
      this.subscribe(0)
    } else this.changeSearch()
  }

  @Watch('routeQueryModelNo')
  onRouteQueryModelNoChanged (n: string) {
    const m = (n === undefined) ? '' : n
    this.selectedModel = m
  }

  get routeQueryModelNo () {
    return this.$route.query.modelNo
  }

  get isAdmin () {
    return authStore.isAdmin
  }

  get isDev () {
    return authStore.isDev
  }

  get sameItems () {
    if (this.selectedModel) return true
    if (!this.selectedItems.length) return false
    if (this.selectedItems.length === 1) return true
    const firstItem = this.selectedItems[0]
    const same = this.selectedItems.every(item => item.info.modelNo === firstItem.info.modelNo)
    return same
  }

  get modelSelects () {
    // const total = { value: '', text: '전체', deviceCount: 0 }
    // const items = modelStore.items.filter(model => model.deviceCount)
    // const ls = items.map(model => {
    //   const count = typeof model.deviceCount === 'number' ? model.deviceCount : 0
    //   total.deviceCount += count
    //   return { value: model.id, text: `${model.id} (${count})`, deviceCount: count }
    // })
    // total.text = `전체 (${total.deviceCount})`
    // ls.unshift(total)
    const items = modelStore.items.filter(model => model.deviceCount)
    const ls = items.map(model => {
      return { value: model.id, text: `${model.id} (${model.deviceCount})`, deviceCount: model.deviceCount }
    })
    const totalDeviceCount = modelStore.total.deviceCount
    ls.unshift({ value: '', text: `전체 (${totalDeviceCount})`, deviceCount: totalDeviceCount })
    return ls
  }

  get modelCount () {
    const r = this.modelSelects.find(m => m.value === this.selectedModel)
    return r?.deviceCount || 0
  }

  get items () {
    const items = this.snapshots.map(sn => sn.data())
    if (this.beforeItems.length) {
      items.forEach(item => {
        const r = this.beforeItems.find(bi => bi.id === item.id)
        if (r) item.dialog = r.dialog
      })
    }
    this.beforeItems = items
    return items
  }

  get firstSnapshot () {
    return this.snapshots[0]
  }

  get lastSnapshot () {
    return this.snapshots[this.snapshots.length - 1]
  }

  get xs () { return this.$vuetify.breakpoint.xs }

  get selectedHeaders () {
    return authStore.user?.settings.headers?.filter(header => {
      if (this.selectedModel) {
        if (header.value === 'info.modelNo') return false
      }
      return header.selected
    })
  }

  @Debounce(500)
  async changeSearch () {
    if (!this.searchText) {
      return
    }
    try {
      const options = {
        page: 0,
        hitsPerPage: 10
      }
      const r = await AlgoliaStore.index?.search(this.searchText, options)
      if (!r) {
        this.snapshots = []
        return
      }
      const ids = r.hits.map(hit => hit.objectID)
      if (!ids.length) {
        this.snapshots = []
        return
      }
      let query: firebase.firestore.Query<Device>
      if (this.selectedModel) {
        query = deviceCollection.where('info.modelNo', '==', this.selectedModel)
      } else {
        query = deviceCollection
      }
      this.destroy()

      this.unsubscribe = query
        .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
        .limit(10).onSnapshot(sn => {
          if (sn.empty) {
            this.snapshots = []
            return
          }
          this.snapshots = sn.docs
        })
    } catch (e) {
      console.log('al err')
      console.error(e.message)
    }
  }

  clearListAll () {
    const batch = firebase.firestore().batch()

    this.snapshots.forEach(doc => {
      batch.delete(doc.ref)
    })
    batch.commit()
  }

  setPersistent (value: boolean) {
    this.persistentAction = value
  }

  setSettingsDefault () {
    this.headers = SettingsHeaders
  }

  destroy () {
    if (this.unsubscribe) this.unsubscribe()
  }

  subscribe (arrow: number) {
    this.destroy()
    let query
    if (this.selectedModel !== this.options.sortBy[0]) {
      if (this.selectedModel) {
        query = deviceCollection.where('info.modelNo', '==', this.selectedModel)
      } else {
        query = deviceCollection
      }
      query = query.orderBy(this.options.sortBy[0] || 'modemNo', this.options.sortDesc[0] ? 'desc' : 'asc')
    } else {
      query = deviceCollection.orderBy(this.options.sortBy[0] || 'modemNo', this.options.sortDesc[0] ? 'desc' : 'asc')
    }
    const limit = this.options.itemsPerPage
    switch (arrow) {
      case -1:
        query = query.endBefore(this.firstSnapshot).limitToLast(limit)
        break
      case 1:
        query = query.startAfter(this.lastSnapshot).limit(limit)
        break
      default:
        query = query.limit(limit)
        break
    }
    this.unsubscribe = query.onSnapshot(sn => {
      // console.log(`${new Date()} sub ${sn.size}`)
      if (sn.empty) return
      this.snapshots = sn.docs
    }, (e) => {
      console.error(e.message)
    })
  }

  clear () {
    if (this.selectedModel && this.options.sortBy[0] === 'info.modelNo') this.options.sortBy[0] = 'modemNo'
    this.options.page = 1
    this.selectedItems = []
    this.subscribe(0)
    if (this.searchText) this.searchText = ''
  }

  openActions () {
    // if (!this.selectedItems.length) return
    if (this.selectedItems.length) {
      const firstItem = this.selectedItems[0]
      const same = this.selectedItems.every(item => item.info.modelNo === firstItem.info.modelNo)
      if (!same) {
        alert('같은 모델만 선택할 수 있음')
        return
      }
    }
    this.form.firmware = null
    this.form.update = null
    this.form.log = null
    this.showAction = true
  }

  changeItemUpdate (item: Device) {
    logAdd('update', `${item.id}`, `업데이트 ${item.update ? '예약' : '중지'}`, false, `/device/${item.id}`)
    deviceCollection.doc(item.id).set({ update: item.update }, { merge: true })
  }

  clearItemUpdate (item: Device) {
    deviceCollection.doc(item.id).set({ update: 0 }, { merge: true })
  }

  changeItemLog (item: Device) {
    deviceCollection.doc(item.id).set({ log: item.log }, { merge: true })
  }

  changeItemView (item: Device) {
    deviceCollection.doc(item.id).set({ view: item.view }, { merge: true })
  }

  changeItemGroup (item: Device) {
    deviceCollection.doc(item?.id).set({ group: item?.group }, { merge: true })
  }

  modelGroups (item: Device) {
    if (!item) return []
    const model = modelStore.items.find(m => m.id === item?.info.modelNo)
    if (!model) return []
    return model.groups
  }

  firmwareSelects (item: Device) {
    return FirmwareStore.items.filter(firmware => firmware.modelNo === item.info.modelNo)

    // return ls.map(firmware => {
    //   return { value: firmware, text: `${firmware.name} ${firmware.version.split('').join('.')}` }
    // })
  }

  setFirmware (item: Device, firmware: Firmware) {
    const message = `펌웨어 변경: ${item.firmware?.id} -> ${firmware.id}`
    logAdd('update', `${item.id}`, message, false, `/device/${item.id}`)
    deviceCollection.doc(item.id).set({
      firmware: firmwareCollection.doc(firmware.id)
    }, { merge: true })
  }
}
