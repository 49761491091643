
































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DeviceAdd extends Vue {
  modemNo = '1211111111'
  plateNo = '11가1111'
  modelNo = 'LDT-100BS'
  addCount = 1
  async save () {
    const device = {
      info: {
        time: new Date(),
        driverName: 'A',
        driverCode: '123456789011234567',
        carType: 11,
        plateNo: this.plateNo,
        vin: '123456789ABCDEFG',
        companyName: 'A',
        companyNo: '1234567890',
        approvalNo: '',
        serialNo: '',
        modelNo: this.modelNo,
        kFactor: 5096,
        rpmFactor: 200,
        version: '1111',
        latLng: { lat: 37.1, lng: 127.1 },
        azimuth: 111
      },
      deviceType: 3,
      modemNo: this.modemNo,
      updatedAt: new Date(),
      createdAt: new Date(),
      update: 0,
      log: false,
      firmware: null
    }
    const ref = this.$firebase.firestore().collection('devices').doc(this.modemNo)
    await ref.set(device)
    this.modemNo = (Number(this.modemNo) + 1).toString()
    const ps = this.plateNo.split('가')
    ps[1] = (Number(ps[1]) + 1).toString()
    this.plateNo = ps.join('가')
  }

  async submit () {
    for (let i = 0; i < this.addCount; i++) {
      await this.save()
    }
  }

  async clear () {
    const sn = await this.$firebase.firestore().collection('devices').get()
    sn.docs.forEach(doc => doc.ref.delete())
  }
}
