



import { Vue, Component } from 'vue-property-decorator'
import DeviceList from '@/components/device/list.vue'

@Component({ components: { DeviceList } })
export default class ViewDevice extends Vue {
  get modelNo () {
    const modelNo = this.$route.query.modelNo
    if (!modelNo) return null
    if (modelNo === '전체') return null
    return modelNo
  }
}
