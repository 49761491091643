











































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import deviceCollection, { Device } from '@/models/devices'
import firmwareStore from '@/store/firmware'
import firebase from '@/plugins/firebase'
import firmwareCollection, { Firmware } from '@/models/firmwares'
import modelStore from '@/store/model'
import { swalUpdate, swalDelete } from '@/plugins/alert'
import logAdd from '@/components/log/add'
import delay from 'delay'

@Component<DeviceListActions>({
  created () {
    this.selectedModel = this.modelNo
    this.selected = !!this.devices.length
  }
})
export default class DeviceListActions extends Vue {
  @Prop({ type: String }) modelNo!: string
  @Prop({ type: Array, required: true }) devices!: Device[]
  step = 1
  selected = false
  selectedFirmware = false
  firmware: Firmware | null = null
  selectedUpdate = false
  update = 0
  selectedLog = false
  log = false
  selectedView = false
  view = false
  processCount = 0
  loading = false
  modelStore = modelStore
  selectedModel = ''
  findGroup = ''
  group = ''
  selectedGroup = false
  remark = ''
  selectedRemark = false

  get xs () {
    return this.$vuetify.breakpoint.xs
  }

  get firmwareSelects () {
    let ls: Firmware[] = []
    if (this.selected) {
      if (this.devices.length) ls = firmwareStore.items.filter(item => item.modelNo === this.devices[0].info.modelNo)
    } else {
      ls = firmwareStore.items.filter(item => item.modelNo === this.selectedModel)
    }
    return ls.map(item => {
      return { value: item, text: `${item.name} ${item.version.split('').join('.')}` }
    })
  }

  get selectedDevices () {
    const items = []
    let len = this.devices.length
    if (len > 4) len = 4
    for (let i = 0; i < len; i++) {
      const item = this.devices[i]
      items.push(item)
    }
    return items
  }

  get submitDisabled () {
    if (!this.selectedFirmware &&
    !this.selectedGroup &&
    !this.selectedRemark &&
    !this.selectedUpdate &&
    !this.selectedLog &&
    !this.selectedView) return true
    return false
  }

  get modelSelects () {
    // const ls = modelStore.items.map(model => {
    //   const count = typeof model.deviceCount === 'number' ? model.deviceCount : 0
    //   return { value: model.id, text: `${model.id} (${count})`, deviceCount: count }
    // })
    // return ls

    const items = modelStore.items.filter(model => model.deviceCount)
    const ls = items.map(model => {
      const count = typeof model.deviceCount === 'number' ? model.deviceCount : 0
      return { value: model.id, text: `${model.id} (${count})`, deviceCount: count }
    })
    return ls
  }

  get modelDeviceCount () {
    if (!this.selectedModel) return 0
    const item = modelStore.items.find(item => item.id === this.selectedModel)
    return item?.deviceCount
  }

  get modelGroups () {
    if (!this.selectedModel) return []
    const model = modelStore.items.find(m => m.id === this.selectedModel)
    if (!model) return []
    return model.groups
  }

  async save (type: string) {
    const r = type === 'update' ? await swalUpdate() : await swalDelete()
    if (!r.value) return

    try {
      this.$emit('persistent', true)
      this.loading = true
      this.processCount = 0
      const set: Partial<Device> = {}
      if (this.selectedFirmware) set.firmware = firmwareCollection.doc(this.firmware?.id)
      if (this.selectedUpdate) set.update = this.update
      if (this.selectedLog) set.log = this.log
      if (this.selectedView) set.view = this.view
      if (this.selectedGroup) set.group = this.group
      if (this.selectedRemark) set.remark = this.remark
      if (!this.selected) {
        const ref = deviceCollection
        let query = ref.where('info.modelNo', '==', this.modelNo)
        if (this.findGroup) query = query.where('group', '==', this.findGroup)
        const limit = 10
        if (!this.model) return
        let last = null
        const len = Math.ceil(this.model?.deviceCount / limit)
        for (let i = 0; i < len; i++) {
          this.processCount += limit
          if (this.processCount > this.model?.deviceCount) this.processCount = this.model?.deviceCount
          if (last) query = query.startAfter(last)
          const sn = await query.limit(limit).get()
          if (sn.empty) continue
          last = sn.docs[sn.docs.length - 1]
          const devices = sn.docs.map(doc => doc.data())
          const batch = firebase.firestore().batch()
          for (const device of devices) {
            const ref = deviceCollection.doc(device.id)
            if (type === 'update') {
              if (device.update < 2) batch.set(ref, set, { merge: true })
            } else {
              // const lsn = await ref.collection('logs').get()
              // lsn.docs.forEach(l => batch.delete(l.ref))
              batch.delete(ref)
            }
          }
          await batch.commit()
          await delay(100)
        }
        if (type === 'update') {
          logAdd('update', this.modelNo, `일괄 업데이트 ${set.update ? '예약' : '중지'}`, true, '/device')
        } else {
          logAdd('update', this.modelNo, '일괄 삭제', true, '/device')
        }
      } else {
        // if (this.model) this.processCount = this.model?.deviceCount
        if (this.devices.length > 500) throw Error('너무 많습니다')
        let batch = firebase.firestore().batch()
        const ids = []
        for (const device of this.devices) {
          this.processCount++
          const ref = deviceCollection.doc(device.id)
          if (type === 'update') {
            if (device.update < 2) batch.set(ref, set, { merge: true })
          } else {
            // const lsn = await ref.collection('logs').get()
            // lsn.docs.forEach(l => batch.delete(l.ref))
            batch.delete(ref)
          }
          // if (ids.length < 5)
          ids.push(device.id)
          if (this.processCount % 10 === 0) {
            await batch.commit()
            batch = firebase.firestore().batch()
            await delay(100)
          }
        }
        await delay(100)
        await batch.commit()

        if (type === 'update') {
          logAdd('update', `선택(${this.devices.length})`, `${ids.join(' ')} 일괄 업데이트 ${set.update ? '예약' : '중지'}`, true, '/device')
        } else {
          logAdd('update', `선택(${this.devices.length})`, `${ids.join(' ')} 일괄 삭제`, true, '/device')
        }
      }
    } finally {
      this.$emit('persistent', false)
      this.loading = false
      this.$emit('close')
    }
  }

  get model () {
    return modelStore.items.find(model => model.id === this.modelNo)
  }

  get progressValue () {
    const percent = this.model
      ? Math.floor(this.processCount * 100 / this.model?.deviceCount)
      : Math.floor(this.processCount * 100 / this.devices.length)
    if (percent > 100) return 100
    return percent
  }
}
